import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import Social from "./Social";

const Navbar = ({ bgColor }) => {
  const [nav, setNav] = useState(false);
  const location = useLocation();

  const handleNav = () => {
    setNav(!nav);
  };

  const navItems = [
    { id: 1, text: "Anasayfa", link: "/" },
    { id: 2, text: "Menümüz", link: "/menu" },
    { id: 3, text: "İletişim", link: "/contact" },
  ];

  const isActive = (path) => location.pathname === path;

  return (
    <div className="relative flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
      <Link to="/" className="text-2xl font-bold text-white">
        Hanbaga Burger
      </Link>

      <div className="flex items-center justify-between">
        <div className="mr-4">
          <Social />
        </div>

        <ul className="hidden md:flex">
          {navItems.map((item) => (
            <li
              key={item.id}
              className={`p-4 pt-5 rounded-xl m-2 cursor-pointer duration-300 hover:font-bold ${
                isActive(item.link) ? "text-orange-500" : ""
              }`}
            >
              <Link to={item.link}>{item.text}</Link>
            </li>
          ))}
        </ul>

        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>

        <div
          className={`${
            nav ? "fixed inset-0 bg-black opacity-60 z-10" : "hidden"
          }`}
          onClick={handleNav}
        ></div>

        <ul
          className={
            nav
              ? `${bgColor} fixed z-20 md:hidden left-0 top-20 w-[55%] border border-gray-900 ease-in-out duration-500`
              : `${bgColor} ease-in-out w-[55%] duration-500 fixed top-0 left-[-100%]`
          }
        >
          {navItems.map((item) => (
            <li
              key={item.id}
              className={`p-4 pt-5 border-b rounded-xl duration-300 hover:font-bold cursor-pointer border-gray-600 ${
                isActive(item.link) ? "text-orange-500" : ""
              }`}
            >
              <Link to={item.link}>{item.text}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
